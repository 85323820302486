.tab-title a {
  color: rgb(0, 0, 0) !important;
}

.img-thump {
  /* width: 200px; */
  height: 90px;
  margin-right: 10px;
  border: 4px solid white;
  cursor: pointer;
}
/* .img-thump :hover {

} */
.drag-icon {
  width: 22px;
}

.dragging-helper-class td {
  /* background-color: rgb(255, 0, 0) !important; */
  /* box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25) !important; */
  list-style: none !important;
  /* margin-top: -20px !important; */
  width: 1% !important;
  padding: 0 !important;
  margin: 0 !important;
}
.wrap-Table table {
  width: 100% !important;
  border-collapse: collapse !important;
  table-layout: fixed !important;
}
.wrap-Table td {
  white-space: normal !important;
  word-wrap: break-word !important;
}
.react-read-more-read-less-more {
  color: #20a8d8 !important;
  cursor: pointer !important;
}
.react-read-more-read-less-less {
  color: #20a8d8 !important;
  cursor: pointer !important;
}
.Select-menu-outer {
  z-index: 999 !important;
}

.cursorChange {
  cursor: pointer;
}
.required {
  margin-left: 2px;
  color: red;
}
.switch-label .switch-slider::before {
  z-index: 0 !important;
}
.tb-font-bold td {
  font-weight: bold;
}
.badge {
  border-radius: 50% !important;
  background-color: red !important;
  color: white !important;
  display: inline-block;
  padding: 0.25em 0.4em !important;
  font-size: 75% !important;
  font-weight: 700 !important;
  line-height: 1 !important;
}

.mybuttonoverlap {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  visibility: hidden;
}

.mydivouter:hover .mybuttonoverlap {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  visibility: visible;
}
